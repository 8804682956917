/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import CommentDialog from 'src/components/comment-dialog';
import Iconify from 'src/components/iconify/iconify';
import Scrollbar from 'src/components/scrollbar';
import { TablePaginationCustom, useTable } from 'src/components/table';
import { fCurrency } from 'src/utils/format-number';
import { calculateClientUnitPrice } from 'src/utils/quoteHelperFunc';
import CommercialInvoiceLevel2Dialog from './commercialInvoiceLevel2Dialog';
import { LoadingButton } from '@mui/lab';
import { setl2complete } from 'src/services/quotes';
import { useParams, useRouter } from 'src/routes/hooks';
import { useSnackbar } from 'src/components/snackbar';
import { ArrowCircleDown, ArrowCircleUp, North, South } from '@mui/icons-material';
import { LineItemMoveDownApi, LineItemMoveUpApi, updateWarrantyApi } from 'src/services/common';
import { HasAccess } from '@permify/react-role';
import CommentsModal from 'src/components/comments/index';
import { addNewNotification } from 'src/services/notification';
import CountComment from 'src/components/comments/CountComment';
import { primaryPresets } from 'src/theme/options/presets';

function createData(
  checkBox,
  _id,
  slNo,
  desc,
  pn,
  unitPrice,
  totalPrice,
  qty,
  make,
  approvalAdmin,
  warrantyPeriod,
  index
) {
  return {
    checkBox,
    _id,
    slNo,
    desc,
    pn,
    unitPrice,
    totalPrice,
    qty,
    make,
    approvalAdmin,
    warrantyPeriod,
    index,
  };
}

export default function AccClientLevel2Table({ response, setRefresh, refresh }) {
  const table = useTable({ defaultRowsPerPage: 10000 });
  const [showDialog, setShowDialog] = useState(false);
  const [warrantyPeriod, setWarrantyPeriod] = useState('');
  const [warrantyStartDate, setWarrantyStartDate] = useState('');
  const [showInvoiceDialog, setShowInvoiceDialog] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();
  const { id } = useParams();
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [commentId, setCommentId] = useState(null);

  const handleComments = (row) => {
    setCommentId(row?._id);
    setIsCommentOpen(true);
  };

  useEffect(() => {
    if (response?.line_items?.length) {
      const rowArr = response?.line_items?.map((list, ind) => {
        return createData(
          list?._id,
          list?._id,
          list?.serialNumber,
          list?.description ? list?.description : '--',
          list?.PN ? list?.PN : '--',
          fCurrency(calculateClientUnitPrice(list, response), response?.currency?.symbol),
          fCurrency(
            calculateClientUnitPrice(list, response) * list?.quantity,
            response?.currency?.symbol
          ),
          list?.quantity ? list?.quantity : '--',
          list?.make ? list?.make : '--',

          response?.InitiatePaymentApproval,
          list?.warranty ? list?.warranty : '',
          ind
        );
      });

      setRowData(rowArr);
    }
  }, [response]);

  const COLUMNS = [
    {
      id: 'checkBox',
      label: <Iconify width={20} icon="carbon:checkbox" />,
      minWidth: 50,
      align: 'center',
      render: () => <Iconify width={20} icon="carbon:checkbox" />,
    },
    {
      label: 'Action',
      id: 'action',
      width: 250,
      align: 'center',
      render: (value, row, length) => (
        <Box mr={2}>
          <Stack>
            {row?.index === 0 ? (
              ''
            ) : (
              <Button onClick={() => MoveUp(id, row?._id)}>
                {' '}
                <ArrowCircleUp sx={{ color: 'red' }} />
              </Button>
            )}
            {row?.index + 1 === length ? null : (
              <Button onClick={() => MoveDown(id, row?._id)}>
                <ArrowCircleDown sx={{ color: 'red' }} />
              </Button>
            )}
            {length === 1 ? <Typography variant="caption">No Action</Typography> : null}
          </Stack>
        </Box>
      ),
    },
    {
      id: 'slNo',
      label: 'SNO.',
      minWidth: 50,
      align: 'center',
    },
    {
      id: 'desc',
      label: 'Description',
      minWidth: 150,
      align: 'center',
      render: (value, row) => (
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography style={{ wordBreak: 'break-word', width: 300, pl: 3 }}>{value}</Typography>
        </Box>
      ),
    },
    {
      id: 'pn',
      label: 'P/N',
      minWidth: 200,
      align: 'center',
    },
    {
      id: 'unitPrice',
      label: '(Client) Unit Price',
      minWidth: 120,
      align: 'center',
      // render: (value) => fCurrency(value),
    },
    {
      id: 'totalPrice',
      label: '(Client) Total Price',
      minWidth: 130,
      // render: (value) => fCurrency(value),
    },
    {
      id: 'qty',
      label: 'QUANTITY',
      minWidth: 100,
      align: 'center',
    },
    {
      id: 'make',
      label: 'Make',
      minWidth: 200,
      align: 'center',
      render: (value, row) => <Typography style={{ wordBreak: 'break-word' }}>{value}</Typography>,
    },

    {
      id: 'comment',
      align: 'center',
      label: 'Comments',
      minWidth: 170,
      render: (value, row) => (
        <Box
          onClick={() => {
            setShowDialog(true);
            handleComments(row);
          }}
          display="flex"
          flexDirection="column"
        >
          <Box sx={{ ml: 2.5 }}>
            {/* <Iconify width={20} icon="ic:outline-chat" /> */}
            <CountComment projectId={row?._id} isCommentOpen={isCommentOpen} />
          </Box>
        </Box>
      ),
    },
    {
      id: 'approvalAdmin',
      label: 'Approval of Admin',
      minWidth: 100,
      align: 'center',
      render: (value, row) => {
        // console.log(value, row);
        return (
          <Chip
            style={{ textTransform: 'uppercase' }}
            color={value ? 'success' : 'warning'}
            label={value ? 'Approved' : 'Pending'}
          />
        );
      },
    },
    {
      id: 'warrantyPeriod',
      label: 'Warranty Period',
      minWidth: 260,
      align: 'center',
      render: (value, row) => (
        // console.log("vflvfvlflvf", value,row)
        <Box>
          <TextField
            fullWidth
            label="Enter Warranty Period"
            defaultValue={value}
            onChange={(e) => onChangeWarrantyPeriod(e.target?.value, row?._id)}
          />
        </Box>
      ),
    },
  ];

  const MoveDown = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveDownApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };

  const MoveUp = (id, lineItem_id) => {
    setLoading(true);
    LineItemMoveUpApi(id, lineItem_id)
      .then(() => {
        setRefresh(refresh + 1);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
      });
  };

  const onChangeWarrantyStartDate = (e) => {
    setWarrantyStartDate(e.target.value);
  };

  const onCompleteL2 = () => {
    setLoading(true);
    setl2complete(id)
      .then(() => {
        setLoading(false);
        enqueueSnackbar('Marked l2 task completed');
        router.push('/home/accounting');
      })
      .catch((err) => {
        setLoading(false);
        enqueueSnackbar('Error! Please try again');
        router.push('/home/accounting');
      });
  };

  const addNotification = () => {
    const newNotification = {
      projectId: id,
      pageName: 'ACCOUNTING CLIENT - LEVEL 2',
      title: `ACCOUNTING CLIENT - LEVEL 2 PENDING FOR APPROVAL : ${response.projectId}`,
      desc: 'NEW REQUEST COMING FROM ACCOUNTING CLIENT - LEVEL 2 FOR APPROVAL',
      redirectURL: `/accounting-client-level2/${id}`,
    };

    addNewNotification(newNotification)
      .then(() => {
        // enqueueSnackbar('Notification Added');
      })
      .catch((err) => {
        // enqueueSnackbar('Error! Please try again');
      });
  };

  const onChangeWarrantyPeriod = (val, item_id) => {
    updateWarrantyApi(id, item_id, val);
  };
  return (
    <Box>
      <CommentsModal
        isCommentOpen={isCommentOpen}
        setIsCommentOpen={setIsCommentOpen}
        id={commentId}
      />
      <Paper elevation={1} sx={{ p: 2 }}>
        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar sx={{ maxHeight: '80vh' }}>
            <Table stickyHeader sx={{ minWidth: 800 }}>
              <TableHead>
                <TableRow>
                  {COLUMNS.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{
                        minWidth: column.minWidth,
                        textTransform: 'uppercase',
                        border: 1,
                        background: '#D9D9D9',
                        color: primaryPresets[11].main,
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {rowData
                  ?.slice(
                    table.page * table.rowsPerPage,
                    table.page * table.rowsPerPage + table.rowsPerPage
                  )
                  .map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                      {COLUMNS.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell
                            style={{ verticalAlign: 'top', textTransform: 'uppercase' }}
                            key={column.id}
                            align={column.align}
                          >
                            {column.render ? column.render(value, row, rowData?.length) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Paper>

      <CommentDialog open={showDialog} handleClose={setShowDialog} />
      <CommercialInvoiceLevel2Dialog open={showInvoiceDialog} handleClose={setShowInvoiceDialog} />
    </Box>
  );
}
