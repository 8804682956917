import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  Backdrop,
  CircularProgress
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useMemo } from 'react';
import Iconify from 'src/components/iconify/iconify';
import { useTable } from 'src/components/table';
import { useParams } from 'src/routes/hooks';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSnackbar } from 'src/components/snackbar';
import { approveQuote, archiveVendorRequestService, getQuotedProjectDetails, rejectQuote } from 'src/services/vendor';
export default function Row(props) {
  const {
    row,
    columns,
    secondaryColumns,

    colSpan = 6,
    isItemSelected = false,
    labelId = '',
    handleClick = () => null,
    enableCheckBox = true,
  } = props || {};
  const table = useTable({ defaultRowsPerPage: 10 });
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const [quotedProjectData, setQuotedProjectdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(0);

  const BlurredBackground = styled('div')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.2,
    zIndex: 999,
  });

  useEffect(() => {
    getQuotedProjectDetails(id, row._id).then((data) => {
      // console.log(data, 'quoted data', row);
      setQuotedProjectdata(data);
    });
  }, [update]);

  
  const HandleArchiveRequest = async (id) => {
    setLoading(true);
    await archiveVendorRequestService(id).then((response) => {
      setUpdate(update + 1);
      setQuotedProjectdata([]);
    });
    setLoading(false);
  };

  return (
    <>
     {loading && (
        <BlurredBackground>
          <Backdrop open={loading} style={{ zIndex: 1000 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </BlurredBackground>
      )}
      <TableRow
        onClick={(event) => handleClick(event, row?.id)}
        aria-checked={isItemSelected}
        selected={isItemSelected}
        hover
        role="checkbox"
        tabIndex={-1}
        key={row.code}
      >
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? (
              <Iconify width={20} icon="formkit:up" />
            ) : (
              <Iconify width={20} icon="formkit:down" />
            )}
          </IconButton>
        </TableCell>
        {enableCheckBox && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                'aria-labelledby': labelId,
              }}
            />
          </TableCell>
        )}
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell key={column.id} align={column.align}>
              <Typography textTransform="uppercase">
                {' '}
                {column.render ? column.render(value, row) : value}
              </Typography>
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={colSpan}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography textTransform="uppercase" variant="h6" gutterBottom component="div">
                Data
              </Typography>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {secondaryColumns.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{
                          minWidth: column.minWidth,
                          textTransform: 'uppercase',
                          border: 1,
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{
                        position: 'sticky',
                        right: 0,
                        //background: '#fff',
                        zIndex: 200,
                        borderLeft: '1px solid #fafafa',
                        textTransform: 'uppercase',
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotedProjectData.map((innerRow) => (
                   innerRow?.isLocked ? null: <CustomTableRow COLUMNS={secondaryColumns} row={{ ...innerRow }} HandleArchiveRequest={HandleArchiveRequest} />
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const CustomTableRow = ({ COLUMNS, row, currency,HandleArchiveRequest }) => {
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [errors, setErrors] = useState({});
  const QuoteSchema = Yup.object().shape({
    price: Yup.number().min(1).required('price is required'),
    finalPrice: Yup.number()
      // .max(Yup.ref('price'), 'Final price must be less than or equal to price')
      .required('Required'),
    quantityApproved: Yup.number()
      .max(Yup.ref('quantity'), 'Approved quantity must be less than or equal to quoted quantity')
      .required('quantity is required'),
    isApproved: Yup.boolean(),
  });
  const [values, setValues] = useState({
    ...row,
    quantityApproved: row.quantityApproved || row?.quote?.quantity,
    quantity: row?.quote?.quantity,
  })
  const defaultValues = useMemo(
    () => ({
      ...row,
    quantityApproved: row.quantityApproved || row?.quote?.quantity,
    quantity: row?.quote?.quantity,
    }),
    [row, currency]
  );


  const methods = useForm({
    resolver: yupResolver(QuoteSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,

    formState: { isSubmitting },
  } = methods;

  // const values = watch();

  const handleUpdateValue = (fieldName, targetVal) => {
    setValues({ ...values, [fieldName]: targetVal });
  };

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const handleSubmit = async(data) => {
    try {
      QuoteSchema.validateSync(values, { abortEarly: false });
      await approveQuote(values?._id, values.quantityApproved, values.finalPrice);
      enqueueSnackbar('Quote Selected ');
      setValue('isApproved', true);
      setErrors({});
    } catch (error) {
      enqueueSnackbar('Error in Approving Quote!', { variant: 'error' });
      setErrors(
        error.inner.reduce((errors, err) => {
          errors[err.path] = err.message;
          return errors;
        }, {})
      );
    }
  };

  console.log(values,"rowashash")

  const handleReject = async(data) => {
    try {
      await rejectQuote(row?._id);
      enqueueSnackbar('Quote Request Rejected');
      setValue('isActive', false);
    } catch (error) {
      enqueueSnackbar('Error in Rejecting Quote!', { variant: 'error' });
      console.error(error);
    }
  };
  // console.log(errors);
  return (
    // <FormProvider {...methods}>
      <TableRow tabIndex={-1} key={row.code}>
        {COLUMNS.map((column) => {
          const value = values[column.id];
          return (
            <TableCell key={column.id} align={column.align} style={{ textTransform: 'uppercase' }}>
              {column.render ? column.render(values, row, handleUpdateValue, errors) : value}
            </TableCell>
          );
        })}
        <TableCell
          key="action"
        >
          <Box sx={{display:"flex", flexDirection:"column"}}>
          <Button
            disabled={values.isApproved}
            style={{ textTransform: 'uppercase' }}
            variant="outlined"
            onClick={handleSubmit}
          >
            {values.isApproved ? 'Save' : 'Submit'}
          </Button>
          {values.isApproved  ? null :
          <Button
          sx={{mt:1}}
          style={{ textTransform: 'uppercase' }}
          variant="outlined"
          onClick={handleReject}
        >
          Reject
        </Button>
          }
          {row?.type === 'eshop' ? (
            <Button
              // disabled={values.isApproved}
              style={{ textTransform: 'uppercase' }}
              variant="outlined"
              href={`/quote/${row?.projectId}/eshop`}
              // onClick={onSubmit}
          sx={{mt:1}}
            >
              GO TO Eshop
            </Button>
          ) : (
            <>
            <Button
              // disabled={values.isApproved}
              style={{ textTransform: 'uppercase' }}
              variant="outlined"
          sx={{mt:1}}
              href={`/${id}/${row.partId}/vendor-quote-submission/${row?.vendorId}`}
              // onClick={onSubmit}
            >
              GO TO VENDOR
            </Button>
            <Button
         style={{ marginTop: 5, textTransform: 'uppercase', backgroundColor: '#26275c', color:'white' }}
         variant="contained"
         onClick={(e) => HandleArchiveRequest(row?._id)}
       >
         Archive
       </Button>
            </>
          )}
          </Box>
        </TableCell>
      </TableRow>
    // </FormProvider>
  );
};
